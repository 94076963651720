<script lang="ts">
					import { defineComponent } from 'vue'
					export default defineComponent({
						name: "undefined"
					})
				</script><template>
	<el-config-provider :locale="zhCn">
		<router-view />
	</el-config-provider>
</template>

<script lang="ts" setup>
import { ElConfigProvider } from "element-plus";
import zhCn from "element-plus/lib/locale/lang/zh-cn";
</script>
<style lang="scss" src="./assets/css/index.scss">

</style>

import i18n from "i18next";
import zh from "./lang/zh";
import en from "./lang/en";
import { merge } from "lodash";

import LanguageDetector from "i18next-browser-languagedetector";

const create = (data: any) => {
	const messages = {
		zh,
		en
	};
	for (const key in data) {
		merge(messages[key], data[key]);
	}
	// const i18n = createI18n({
	// 	locale: localStorage.getItem("locale") || navigator.language.slice(0, 2),
	// 	messages: messages
	// });

	i18n.use(LanguageDetector).init({
		resources: {
			en: { translation: messages["en"] },
			zh: { translation: messages["zh"] }
		},
		fallbackLng: "en",
		debug: true,
		react: {
			useSuspense: false
		},
		detection: {
			order: ["querystring", "navigator", "localStorage"],
			lookupQuerystring: "lang"
		}
	});

	return i18n;
};

export default create;

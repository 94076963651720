import { createApp } from "vue";
import App from "./App.vue";
import { bootstrap } from "./cool";
import "./cool/utils/number";
import createI18n from "./modules/i18n";
import getLanguage from "./utils/lang";
import I18NextVue from "i18next-vue";

const app = createApp(App);
// 启动
bootstrap(app)
	.then(async () => {
		// 加载数据
		const langExtraResp: any = await getLanguage();
		const i18n = createI18n(langExtraResp);
		app.use(I18NextVue, { i18next: i18n }).mount("#app");
	})
	.catch((err) => {
		console.error("速哇3D摄影平台 启动失败", err);
	});
